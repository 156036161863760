import React, { Component } from 'react'
import { Container, Header } from 'semantic-ui-react'

interface INotFoundProps {
}

interface INotFoundState {
}

export default class NotFound extends Component<INotFoundProps, INotFoundState> {
  constructor (props: INotFoundProps) {
    super(props)
    this.state = {}
  }

  public render () {
    return (
      <Container textAlign='center' text={true}>
        <br/><br/>
        <div>
          <Header as='h2' icon={true} textAlign='center'>
            <Header.Content style={{ color: 'white' }}>This page was not found</Header.Content>
            <Header.Subheader style={{ color: 'white' }}>
              <b style={{ color: '#326589' }}>Error 404</b>
            </Header.Subheader>
          </Header>
        </div>
      </Container>
    )
  }
}
