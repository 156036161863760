import React, { Component, SyntheticEvent } from 'react'
import { Container, Dimmer, Form, Header, Loader } from 'semantic-ui-react'

import EventEmitter from '../EventEmitter'
import BucketService from '../services/Bucket'

import { IFile, IBucket } from '../index.d'

interface IUpdateProps {
  eventEmitter: EventEmitter,
  match: {
    params: {
      bucketId: string
    }
  }
}

interface IUpdateState {
  isLoading: boolean,
  bucketId: string,
  bucketName: string,
  bucketUrls: IFile[],
  bucketUrlsCount: number,
  bucketUrl: string,
  bucketCryptokeyUrl: string,
  bucketLastChecked: string
}

export default class Update extends Component<IUpdateProps, IUpdateState> {
  private eventEmitter: EventEmitter

  constructor (props: IUpdateProps) {
    super(props)
    this.state = {
      isLoading: false,
      bucketId: '',
      bucketName: '',
      bucketUrls: [],
      bucketUrlsCount: 0,
      bucketUrl: '',
      bucketCryptokeyUrl: '',
      bucketLastChecked: ''
    }
    this.eventEmitter = props.eventEmitter

    this.onBucketNameInput = this.onBucketNameInput.bind(this)
    this.onBucketUrlsInput = this.onBucketUrlsInput.bind(this)
    this.onBucketUrlInput = this.onBucketUrlInput.bind(this)
    this.onBucketCryptokeyUrlInput = this.onBucketCryptokeyUrlInput.bind(this)
  }

  public render () {
    return (
      <Container textAlign='center' text={true}>
        <br/><br/>
        <div>
          <Header as='h2' icon={true} textAlign='center'>
            <Header.Content style={{ color: 'white' }}>Update Bucket</Header.Content>
            <Header.Subheader style={{ color: 'white' }}>
              <b style={{ color: '#6435C9' }}>{this.state.bucketUrlsCount} URLs</b>
            </Header.Subheader>
          </Header>
          <br/><br/>
          <Form>
            <Form.Group widths='equal'>
              <Form.Input fluid={true} icon='edit' iconPosition='left' placeholder='Bucket Name' value={this.state.bucketName} onChange={this.onBucketNameInput} />
              <Form.Input
                fluid={true}
                action={{ color: 'violet', labelPosition: 'right', icon: 'paper plane', content: 'Update', onClick: this.handleUpdateClick }}
                placeholder='Bucket URL'
                value={this.state.bucketUrl}
              />
            </Form.Group>
            <br/>
            <Form.TextArea
              rows={7}
              placeholder='URLs (1 per row)'
              onChange={this.onBucketUrlsInput}
              value={this.filesToString(this.state.bucketUrls)}
            />
            <br/>
            <Form.Input
              fluid={true} icon='key' iconPosition='left' placeholder='Bucket Cryptokey URL' value={this.state.bucketCryptokeyUrl} onChange={this.onBucketCryptokeyUrlInput}
            />
          </Form>
        </div>
        <Dimmer active={this.state.isLoading}>
          <Loader inverted={true} content='Updating Bucket' />
        </Dimmer>
      </Container>
    )
  }

  private onBucketNameInput (event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      bucketName: event.currentTarget.value
    })
  }

  private onBucketUrlsInput (event: SyntheticEvent<HTMLTextAreaElement>) {
    const textContent = event.currentTarget.value
    const bucketUrls = this.stringToFiles(textContent)
    const bucketUrlsCount = bucketUrls.length

    this.setState({
      bucketUrls,
      bucketUrlsCount
    })
  }

  private onBucketUrlInput (event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      bucketUrl: event.currentTarget.value
    })
  }

  private onBucketCryptokeyUrlInput (event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      bucketCryptokeyUrl: event.currentTarget.value
    })
  }

  public componentDidMount = () => {
    this.loadBucket()
  }

  private handleUpdateClick = (e: any, { name }: any) => {
    this.setState({
      isLoading: true
    })

    const bucketUpdateData: IBucket = {
      uid: this.state.bucketId,
      name: this.state.bucketName,
      files: this.state.bucketUrls,
      cryptokeyUrl: this.state.bucketCryptokeyUrl,
      last_checked: this.state.bucketLastChecked
    }

    setTimeout(() => {
      new BucketService().update(bucketUpdateData)
        .then((response) => {
          if (response == null) {
            throw Error()
          }

          this.setState({
            bucketUrl: location.origin + '/bucket/' + response.uid  // eslint-disable-line
          })
        })
        .catch((err) => {
        })
        .finally(() => {
          this.setState({
            isLoading: false
          })
        })
    }, 800)
  }

  private stringToFiles(textContent: string): IFile[] {
    const urls: IFile[] = []

    const regex = /(http:|https:)/ig
    const lines = textContent.split('\n')
    lines.forEach((line) => {
      if (line.match(regex)) {
        urls.push({
          url: line
        } as IFile)
      }
    })

    return (urls || [])
  }

  private filesToString (files: IFile[]): string {
    let textContent: string = ''

    files.forEach((file: IFile) => {
      textContent += file.url + '\n'
    })

    return textContent
  }

  private loadBucket = () => {
    const bucketId = this.props.match.params.bucketId

    new BucketService().getFull(bucketId)
      .then((bucketData) => {
        this.setState({
          isLoading: false,
          bucketId: bucketId,
          bucketName: bucketData.name,
          bucketUrl: location.origin + '/bucket/' + bucketId,  // eslint-disable-line
          bucketUrls: bucketData.files,
          bucketUrlsCount: bucketData.files.length,
          bucketCryptokeyUrl: bucketData.cryptokeyUrl ? bucketData.cryptokeyUrl : '',
          bucketLastChecked: bucketData.last_checked.toString()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
