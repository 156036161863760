import * as assert from 'assert'
import axios from 'axios'
import jwt from 'jsonwebtoken'

import StorageService from './Storage'

export default class AuthService {
  private readonly TOKEN_KEY: string = 'token'
  private readonly BASE_API: string =
    process.env.REACT_APP_SHAREURL_API_PROTOCOL === 'https' ? // remove port
      process.env.REACT_APP_SHAREURL_API_PROTOCOL + '://' +
      process.env.REACT_APP_SHAREURL_API_BASE + '/api' : // add port
      process.env.REACT_APP_SHAREURL_API_PROTOCOL + '://' +
      process.env.REACT_APP_SHAREURL_API_BASE + ':' +
      process.env.REACT_APP_SHAREURL_API_PORT + '/api'
  private readonly BASE_API_AUTH: string = '/login'

  private readonly AUTH_SUCCESS_CODE: number = 201

  private storage: StorageService

  constructor () {
    this.storage = new StorageService()
  }

  public async login (username: string, password: string): Promise<boolean> {
    try {
      const data = {
        username,
        password
      }

      const response = await axios.post(this.BASE_API + this.BASE_API_AUTH, data)

      if (response.status !== this.AUTH_SUCCESS_CODE) {
        throw new Error(response.data)
      }

      const token = response.data.access_token
      const decoded: string | { [key: string]: any; } | null = this.decodeToken(token)

      if (decoded != null && decoded instanceof Object) {
        assert.strictEqual(decoded.header.typ, 'JWT')
        assert.ok(this.setToken(token))

        return true
      }

      return false
    } catch (err) {
      return false
    }
  }

  public logout (): boolean {
    return this.clearToken()
  }

  public getUserData (): object | null {
    const token = this.getToken()
    if (token == null) {
      return null
    }

    const decoded: string | { [key: string]: any; } | null = this.decodeToken(token)

    if (decoded != null && decoded instanceof Object) {
      if (this.isExpired(decoded.payload)) {
        this.clearToken()
        return null
      }

      return decoded.payload
    }

    return null
  }

  public isExpired (decoded: any): boolean {
    const now = Date.now().valueOf() / 1000

    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
      return true
    }
    if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
      return true
    }

    return false
  }

  public getUserToken (): string | null {
    return this.getToken()
  }

  private decodeToken (token: string): string | { [key: string]: any; } | null {
    try {
      const decoded: any = jwt.decode(token, {
        complete: true
      })

      if (decoded) {
        assert.strictEqual(decoded.header.typ, 'JWT')
        return decoded
      }

      return null
    } catch (err) {
      console.log(err)
      return null
    }
  }

  private setToken (token: string): boolean {
    return this.storage.set(this.TOKEN_KEY, token)
  }

  private getToken (): string | null {
    return this.storage.get(this.TOKEN_KEY)
  }

  private clearToken (): boolean {
    return this.storage.clear(this.TOKEN_KEY)
  }
}
