export const hoster: {
  [key: string]: IHoster
} = {
  'mega.nz': {
    displayName: 'mega.nz',
    icon: '/img/meganz.png',
    priority: 2
  },
  'megaup.net': {
    displayName: 'megaup.net',
    icon: '/img/megaupnet.png',
    priority: 3
  },
  'uploaded.net': {
    displayName: 'uploaded.net',
    icon: '/img/uploadednet.png',
    priority: 4
  },
  'cubeload.me': {
    displayName: 'Torrent File',
    icon: '/img/torrent.png',
    priority: 6
  },
  'firefile.cc': {
    displayName: 'firefile.cc',
    icon: '/img/firefilecc.png',
    priority: 1
  },
  'firefile.cc-torrent': {
    displayName: 'Torrent File',
    icon: '/img/torrent.png',
    priority: 5
  }
}

export interface IHoster {
  displayName: string,
  name: string,
  icon: string,
  priority: number
}
export interface IBucket {
  uid: string,
  name: string,
  last_checked: string,
  files: IFile[],
  cryptokeyUrl?: string,
  parsed?: { [key: string]: IFile[] },
  meta?: any
}

export interface IBucketArchive {
  count: number,
  pages: number,
  data: Array<IBucket>
}

export interface IBucketMeta {
  totalSize: number,
  totalParts: number,
  onlineParts: number,
  offlineParts: number
}

export interface IBucketCreate {
  name: string,
  files: IFile[],
  cryptokeyUrl: string
}

export interface IFile {
  filename: string,
  size: number,
  url: string,
  status: number
}
