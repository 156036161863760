import React, { Component } from 'react'
import { Container, Dimmer, Grid, List, Loader } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'

import BucketFiles from './bucket/BucketFiles'
import BucketHeader from './bucket/BucketHeader'

import EventEmitter from '../EventEmitter'

import BucketService from '../services/Bucket'

import { History } from 'history'
import { hoster, IBucket, IBucketMeta, IFile } from '../index.d'

import BucketInvalidDataError from '../errors/BucketInvalidDataError'
import BucketAds from './bucket/BucketAds'
import { BucketProvider } from './bucket/BucketContext'
import BucketCryptokey from './bucket/BucketCryptokey'

interface IBucketProps {
  eventEmitter: EventEmitter,
  match: {
    params: {
      bucketId: string
    }
  },
  history: History
}

interface IBucketState {
  bucketData: IBucket | null,
  isLoading: boolean,
  hoster: string
}

export default class Bucket extends Component<IBucketProps, IBucketState> {
  private eventEmitter: EventEmitter

  constructor (props: IBucketProps) {
    super(props)

    this.state = {
      bucketData: null,
      isLoading: false,
      hoster: ''
    }

    this.eventEmitter = props.eventEmitter
  }

  public componentDidMount () {
    this.setState({
      isLoading: true
    })

    this.loadBucket()

    this.eventEmitter.on('onHosterChange', (e: Event) => {
      this.setState({
        hoster: e.toString()
      })
      this.forceUpdate()
    })

    /*const referrer = document.referrer
    if (referrer.indexOf('/getgamez.net/') == -1 && referrer.indexOf('/localhost:') == -1) {
      console.log(referrer)
      this.props.history.push('/404')
    }*/
  }

  public componentWillUnmount () {
    this.eventEmitter.emit('name', '')
  }

  public render () {
    return this.state.bucketData !== null ? (
      <BucketProvider value={this.state.bucketData}>
        {this.buildHead()}
        <Container textAlign='left' text={true}>
          <div>
            <br/>
            <BucketHeader eventEmitter={this.eventEmitter}/>
            <Grid columns={1} relaxed='very' stackable={true} divided='vertically'>
              <Grid.Column>
                <br/>
                <BucketFiles eventEmitter={this.eventEmitter}/>
                <br/>
                <List divided={true} relaxed={true} inverted={true}>
                  {(this.state.hoster === 'mega.nz' && this.state.bucketData.cryptokeyUrl) &&

                    <BucketCryptokey eventEmitter={this.eventEmitter} cryptokeyUrl={this.state.bucketData.cryptokeyUrl}/>
                  }
                  <BucketAds eventEmitter={this.eventEmitter}/>
                </List>
              </Grid.Column>
            </Grid>
            <br/>
            <Container fluid={true} textAlign='center'>
              {
                /* <p style={{ color: '#8A73FF' }}>
                  <Icon name='clock' /> Last checked: <b>{moment(this.state.bucketData.last_checked).fromNow()}</b>
                </p> */
              }
            </Container>
          </div>
          <Dimmer active={this.state.isLoading}>
            <Loader indeterminate={true} inverted={true} content='Preparing Files' />
          </Dimmer>
        </Container>
      </BucketProvider>
    ) : (
        <Dimmer active={this.state.isLoading}>
          <Loader indeterminate={true} inverted={true} content='Preparing Files' />
        </Dimmer>
      )
  }

  private buildHead = () => {
    const name = this.state.bucketData ? this.state.bucketData.name : ''
    const title = name + ' - Direct Download (FREE) - ' + document.title
    const description = 'Download ' + name + ' completely for free via MEGA.NZ, UPLOADED and MORE! You can also free download via Torrent File'

    return (
      <Helmet>
        <meta charSet='utf-8' />
        <title>{ title }</title>
        <meta name='description' content={description} />
        <meta name="robots" content="noindex" />
      </Helmet>
    )
  }

  private loadBucket = () => {
    const bucketId = this.props.match.params.bucketId

    setTimeout(() => {
      new BucketService().get(bucketId)
        .then((bucketData) => {
          bucketData.parsed = this.parseBucketFiles(bucketData)
          bucketData.meta = this.parseBucketFilesMeta(bucketData)
          this.setState({
            isLoading: false,
            bucketData,
            hoster: Object.keys(bucketData.parsed).sort((x, y) => {
              return hoster[x].priority - hoster[y].priority
            })[0]
          })
          this.eventEmitter.emit('onHosterChange', Object.keys(bucketData.parsed).sort((x, y) => {
            return hoster[x].priority - hoster[y].priority
          })[0])
        })
        .catch((err) => {
          console.log(err)
        })
    }, 800)
  }

  private parseBucketFiles (bucketData: IBucket) {
    const parsedBucket: { [key: string]: IFile[] } = {}

    bucketData.files.forEach(function (urlObject: IFile) {
      let hoster: string = urlObject.url.split('//')[1].substr(0, urlObject.url.split('//')[1].indexOf('/'))
      urlObject.size = urlObject.size !== undefined ? urlObject.size as number / 1000000000 : 0

      // handle small file sizes
      if (urlObject.size >= 0.5) {
        urlObject.size = Math.round(urlObject.size)
      } else {
        urlObject.size = Math.ceil(urlObject.size)
      }

      if (hoster === 'ul.to') {
        hoster = 'uploaded.net'
      }

      if (hoster === 'firefile.cc' && urlObject.filename.indexOf('.torrent') >= 0) {
        hoster = 'firefile.cc-torrent'
      }

      if (parsedBucket[hoster] === undefined) {
        parsedBucket[hoster] = [urlObject]
      } else {
        parsedBucket[hoster].push(urlObject)
      }
    })

    return parsedBucket
  }

  private parseBucketFilesMeta (bucketData: IBucket) {
    try {
      const bucketMeta: any = []

      if (bucketData.parsed === undefined || bucketData.parsed === {}) {
        return bucketMeta
      }

      Object.keys(bucketData.parsed).forEach((parsedKey) => {
        const meta: IBucketMeta = {
          totalSize: 0,
          totalParts: 0,
          onlineParts: 0,
          offlineParts: 0
        }

        if (bucketData.parsed === undefined || bucketData.parsed[parsedKey] === undefined) {
          throw new Error()
        }

        bucketData.parsed[parsedKey].forEach((bucketDataItem: IFile) => {
          meta.totalSize += bucketDataItem.size
          meta.totalParts += 1
          if (bucketDataItem.status === 1) {
            meta.onlineParts += 1
          } else {
            meta.offlineParts += 1
          }
        })

        bucketMeta[parsedKey] = meta
      })

      return bucketMeta
    } catch (err) {
      throw new BucketInvalidDataError(err.message, 'ENOENT')
    }
  }
}
