import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'

import BucketDropdown from './BucketDropdown'

import EventEmitter from '../../EventEmitter'

import { IBucket } from '../../index.d'
import { BucketContext } from './BucketContext'

interface IBucketHeaderProps {
  eventEmitter: EventEmitter
}

interface IBucketHeaderState {
  hoster: string | number,
}

export default class BucketHeader extends Component<IBucketHeaderProps, IBucketHeaderState> {
  public static contextType: React.Context<IBucket | null> = BucketContext
  private eventEmitter: EventEmitter

  constructor (props: IBucketHeaderProps) {
    super(props)

    this.state = {
      hoster: 0
    }

    this.eventEmitter = props.eventEmitter
  }

  public componentDidMount () {
    this.eventEmitter.on('onHosterChange', (e: Event) => {
      const hoster: string = e.toString()
      this.setState({
        hoster
      })

      this.forceUpdate()
    })
  }

  public render () {
    return (
      <Header as='h2' icon={true} textAlign='center'>
        <Header.Content style={{ color: 'white' }}>{this.getBucketDataByKey('name')}</Header.Content>
        <Header.Subheader style={{ color: 'white' }}>
          <b style={{ color: '#8A73FF' }}>
            {this.getBucketMetaByKey('onlineParts')} / {this.getBucketMetaByKey('totalParts')} Online
            </b> | Size: {this.getBucketMetaByKey('totalSize')} GB
            <br/><br/>
          <BucketDropdown eventEmitter={this.props.eventEmitter}/>
        </Header.Subheader>
      </Header>
    )
  }

  private getBucketDataByKey (key: string) {
    return this.context[key]
  }

  private getBucketMetaByKey (key: string): number {
    const bucketMeta: any = this.context.meta
    const hoster: string | number = this.state.hoster !== 0 ? this.state.hoster : Object.keys(bucketMeta)[0]

    if (bucketMeta[hoster] === undefined || bucketMeta[hoster][key] === undefined) {
      return 0
    }

    return bucketMeta[hoster][key]
  }
}
