import React, { Component } from 'react'
import { Button, Icon, Image, Label, List } from 'semantic-ui-react'

import EventEmitter from '../../EventEmitter'

import { hoster, IBucket, IFile } from '../../index.d'
import { BucketConsumer } from './BucketContext'

interface IBucketFilesProps {
  eventEmitter: EventEmitter
}

interface IBucketFilesState {
  hoster: string,
  buttonsActive: boolean[]
}

export default class BucketFiles extends Component<IBucketFilesProps, IBucketFilesState> {
  private eventEmitter: EventEmitter

  constructor (props: IBucketFilesProps) {
    super(props)
    this.state = {
      hoster: '',
      buttonsActive: []
    }
    this.eventEmitter = props.eventEmitter
  }

  public componentWillMount () {
    this.eventEmitter.on('onHosterChange', (e: Event) => {
      this.setState({
        hoster: e.toString()
      })
      this.clearButtonsActive()
      this.forceUpdate()
    })
  }

  public render () {
    return (
      <BucketConsumer>
        {(bucketContext) => bucketContext && (
          <List divided={true} relaxed={true} inverted={true}>
            {this.getListItems(bucketContext)}
          </List>
        )}
      </BucketConsumer>
    )
  }

  private getShortUrl (url: string): string {
    const Encoder = {
      _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', encode (e: any) {
        let t = ''; let n, r, i, s, o, u, a; let f = 0; e = Encoder._utf8_encode(e); while (f < e.length) {
          n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 }
          t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
        }
        return t
      }, decode (e: any) {
        let t = ''; let n, r, i; let s, o, u, a; let f = 0; e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ''); while (f < e.length) {
          s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) }
          if (a != 64) { t = t + String.fromCharCode(i) }
        }
        t = Encoder._utf8_decode(t); return t
      }, _utf8_encode (e: any) {
        e = e.replace(/\r\n/g, '\n'); let t = ''; for (let n = 0; n < e.length; n++) { const r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } }
        return t
      }, _utf8_decode (e: any) {
        let t = ''; let n = 0; let r = 0; const c1 = r
        let c2 = r
        while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); const c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } }
        return t
      }
    }

    return 'https://cshort.org/short.php?url=' + Encoder.encode(url)
  }

  private handleButtonClick(id: number, url: string, target: string) {
    window.open(url, target)

    let currentButtonsActive = this.state.buttonsActive
    currentButtonsActive[id] = true

    this.setState({
      buttonsActive: currentButtonsActive
    })
  }

  private isButtonActive(id: number): boolean {
    if (this.state.buttonsActive[id]) {
      return true
    } else {
      return false
    }
  }

  private clearButtonsActive() {
    this.setState({
      buttonsActive: []
    })
  }

  private getListItems (bucketData: IBucket) {
    if (bucketData.parsed !== undefined && bucketData.parsed !== {}) {
      const parsed = bucketData.parsed
      const keys = Object.keys(parsed)

      const listItems: any = []

      const key = this.state.hoster !== '' ? this.state.hoster : keys[0]
      const items = parsed[key]

      if (items === undefined) return

      items.forEach((item: IFile, index: number) => {
        listItems.push(
          <List.Item key={item.url}>
            <Image avatar={true} src={hoster[key].icon} />
            <List.Content>
              <List.Header style={{ color: 'white' }}>{item.filename}</List.Header>
              <List.Description style={{ color: '#b0b0b0' }}>{key.replace('-torrent', '')}</List.Description>
            </List.Content>
            <List.Content floated='right'>
              {item.status === 1 ? (
                  <Button size='tiny' as='div' labelPosition='right'>
                    <Button size='tiny' inverted={!this.isButtonActive(index)} color='violet' onClick={() => this.handleButtonClick(index, this.getShortUrl(item.url), '_blank')}>
                      {this.isButtonActive(index) ? (
                        <Icon name='check' hidden={!this.isButtonActive(index)} />
                      ) : (
                        <Icon name='download' hidden={this.isButtonActive(index)} />
                      )}
                      Download
                    </Button>
                    <Label as='a' basic={true} pointing='left'>
                      {item.size} GB
                    </Label>
                  </Button>
              ) : (
                  <Button size='tiny' as='div' labelPosition='right' disabled={true}>
                    <Button size='tiny' style={{ backgroundColor: '#e74c3c', color: 'white' }}>
                      <Icon name='remove' />
                      Offline
                  </Button>
                    <Label as='a' basic={true} color='red' pointing='left'>
                      {item.size} GB
                  </Label>
                  </Button>
                )}
            </List.Content>
          </List.Item>
        )
      })

      return listItems
    }
  }
}
