import React, { Component } from 'react'
import { Container, Dimmer, Icon, Loader, Message, Table, Header, Image, Menu, Button, Pagination, Grid, Search } from 'semantic-ui-react'

import moment from 'moment'

import BucketFiles from './bucket/BucketFiles'
import BucketHeader from './bucket/BucketHeader'
import BucketTableRow from './bucket/BucketTableRow'

import EventEmitter from '../EventEmitter'

import BucketService from '../services/Bucket'

import { History } from 'history'
import { IBucket, IBucketMeta, IFile, IBucketArchive } from '../index.d'

import BucketInvalidDataError from '../errors/BucketInvalidDataError'
import { BucketProvider } from './bucket/BucketContext'

interface IArchiveProps {
  eventEmitter: EventEmitter,
  history: History
}

interface IArchiveState {
  activePage: number,
  count: number,
  pages: number,
  archiveData: Array<IBucket>,
  isLoading: boolean,
  isSearching: boolean,
  search: string
}

export default class Archive extends Component<IArchiveProps, IArchiveState> {
  private eventEmitter: EventEmitter

  constructor (props: IArchiveProps) {
    super(props)

    this.state = {
      activePage: 1,
      count: 0,
      pages: 0,
      archiveData: [],
      isLoading: false,
      isSearching: false,
      search: ''
    }

    this.eventEmitter = props.eventEmitter
  }

  public componentDidMount () {
    this.setState({
      isLoading: true
    })

    this.loadBucketArchive(this.state.search, this.state.activePage)
  }

  public componentWillUnmount () {
  }

  public handlePaginationChange = (e: any, { activePage }: any) => {
    this.setState({
      activePage
    })

    this.loadBucketArchive(this.state.search, activePage)
  }

  public onSearchChange = (e: any, { value }: any) => {
    this.setState({
      isSearching: true,
      search: value
    })
    
    this.loadBucketArchive(value, 1)

    this.setState({
      isSearching: false
    })
  }

  public render () {
    return (
      <Grid centered columns={1} padded>
        <Grid.Column>
          <Table inverted celled singleLine compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Files</Table.HeaderCell>
                <Table.HeaderCell>Last Checked</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.archiveData ?
                (this.state.archiveData.map((bucketData: IBucket, i: number) =>
                  <BucketTableRow key={bucketData.uid} bucketData={bucketData} />
                )): null
              }
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='2'>
                  <Search
                    onSearchChange={this.onSearchChange}
                    loading={this.state.isSearching}
                    showNoResults={false}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='3'>
                  <Pagination
                    floated='right'
                    inverted
                    activePage={this.state.activePage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={this.state.pages}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    )
  }

  private loadBucketArchive = (query: String = '', page: Number = 1) => {
    new BucketService().search(page, query)
      .then((archiveData) => {
        this.setState({
          isLoading: false,
          count: archiveData.count,
          pages: archiveData.pages,
          archiveData: archiveData.data
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
