import React, { Component } from 'react'
import { Button, Icon, List } from 'semantic-ui-react'

import EventEmitter from '../../EventEmitter'

interface IBucketCryptokeyProps {
  eventEmitter: EventEmitter,
  cryptokeyUrl: string
}

interface IBucketCryptokeyState {
}

export default class BucketCryptokey extends Component<IBucketCryptokeyProps, IBucketCryptokeyState> {
  private eventEmitter: EventEmitter
  private cryptokeyUrl: string

  constructor (props: IBucketCryptokeyProps) {
    super(props)

    this.eventEmitter = props.eventEmitter
    this.cryptokeyUrl = props.cryptokeyUrl
  }

  public render () {
    return (
      <List.Item key='cryptokey'>
        <List.Content floated='right'>
          <Button animated={true} style={{ backgroundColor: '#8A73FF' }} onClick={this.gotoCryptokey}>
            <Button.Content hidden={true} style={{ color: 'white' }}>
              <Icon name='arrow right' />
            </Button.Content>
            <Button.Content visible={true} style={{ color: 'white' }}>
              <Icon name='key' />
            </Button.Content>
          </Button>
        </List.Content>
        <List.Icon style={{ color: '#8A73FF' }} size='big' name='lock' />
        <List.Content>
          <List.Header style={{ color: '#8A73FF' }}>Decryption Keys</List.Header>
          <List.Description style={{ color: '#b0b0b0' }}>Passwords for mega.nz files</List.Description>
        </List.Content>
      </List.Item>
    )
  }

  private gotoCryptokey = () => {
    window.open(this.cryptokeyUrl, '_blank')
  }
}
