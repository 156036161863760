import React, { Component, SyntheticEvent } from 'react'
import { Button, Container, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react'

import EventEmitter from '../../EventEmitter'

import InvalidLoginError from '../../errors/InvalidLoginError'
import AuthService from '../../services/Auth'

interface ILoginProps {
  eventEmitter: EventEmitter
}

interface ILoginState {
  loggingIn: boolean
}

export default class Login extends Component<ILoginProps, ILoginState> {
  private eventEmitter: EventEmitter

  constructor (props: ILoginProps) {
    super(props)
    this.state = {
      loggingIn: false
    }
    this.eventEmitter = props.eventEmitter
  }

  public render () {
    return (
      <Container textAlign='center' text={true}>
        <br/><br/>
        <div>
          <Header as='h2' icon={true} textAlign='center'>
            <Header.Content style={{ color: 'white' }}>Login</Header.Content>
            <Header.Subheader style={{ color: 'white' }}>
              <b style={{ color: '#6435C9' }}>Welcome back!</b>
            </Header.Subheader>
          </Header>
          <br/><br/>
          <Grid>
            <Grid.Row centered={true}>
              <Grid.Column width={8}>
                <Form onSubmit={this.handleFormSubmit}>
                  <Form.Input id='username' fluid={true} icon='user' iconPosition='left' placeholder='Username' />
                  <Form.Input id='password' fluid={true} type='password' icon='lock' iconPosition='left' placeholder='Password' />
                  <br/>
                  <Button color='violet' type='submit'>Login</Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Dimmer active={this.state.loggingIn}>
          <Loader inverted={true} content='Logging In' />
        </Dimmer>
      </Container>
    )
  }

  private handleFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    this.setState({
      loggingIn: true
    })

    const elements: any = event.currentTarget.elements
    const username = elements.username.value
    const password = elements.password.value

    setTimeout(() => {
      this.doLogin(username, password).then((successful: boolean) => {
        if (successful === false) {
          throw new Error('invalid credentials')
        }

        this.eventEmitter.emit('auth', 'loggedIn')
      }).catch((err: Error) => {
        throw new InvalidLoginError(err.message)
      })

      this.setState({
        loggingIn: false
      })
    }, 2000)
  }

  private async doLogin (username: string, password: string): Promise<boolean> {
    const authService = new AuthService()
    return authService.login(username, password)
  }
}
