import React, { Component, SyntheticEvent } from 'react'
import { Container, Dimmer, Form, Header, Loader } from 'semantic-ui-react'

import EventEmitter from '../EventEmitter'
import BucketService from '../services/Bucket'

import { IBucketCreate, IFile } from '../index.d'

interface ICreateProps {
  eventEmitter: EventEmitter
}

interface ICreateState {
  isLoading: boolean,
  bucketName: string,
  bucketUrls: IFile[],
  bucketUrlsCount: number,
  bucketUrl: string,
  bucketCryptokeyUrl: string
}

export default class Create extends Component<ICreateProps, ICreateState> {
  private eventEmitter: EventEmitter

  constructor (props: ICreateProps) {
    super(props)
    this.state = {
      isLoading: false,
      bucketName: '',
      bucketUrls: [],
      bucketUrlsCount: 0,
      bucketUrl: '',
      bucketCryptokeyUrl: ''
    }
    this.eventEmitter = props.eventEmitter

    this.onBucketNameInput = this.onBucketNameInput.bind(this)
    this.onBucketUrlsInput = this.onBucketUrlsInput.bind(this)
    this.onBucketUrlInput = this.onBucketUrlInput.bind(this)
    this.onBucketCryptokeyUrlInput = this.onBucketCryptokeyUrlInput.bind(this)
  }

  public render () {
    return (
      <Container textAlign='center' text={true}>
        <br/><br/>
        <div>
          <Header as='h2' icon={true} textAlign='center'>
            <Header.Content style={{ color: 'white' }}>Create Bucket</Header.Content>
            <Header.Subheader style={{ color: 'white' }}>
              <b style={{ color: '#6435C9' }}>{this.state.bucketUrlsCount} URLs</b>
            </Header.Subheader>
          </Header>
          <br/><br/>
          <Form>
            <Form.Group widths='equal'>
              <Form.Input fluid={true} icon='edit' iconPosition='left' placeholder='Bucket Name' value={this.state.bucketName} onChange={this.onBucketNameInput} />
              {this.state.bucketUrl === '' ? (
                <Form.Input
                  fluid={true}
                  action={{ color: 'violet', labelPosition: 'right', icon: 'paper plane', content: 'Create', onClick: this.handleCreateClick }}
                  placeholder='Bucket URL'
                />
              ) : (
                  <Form.Input
                    fluid={true}
                    onChange={this.onBucketUrlInput}
                    value={this.state.bucketUrl}
                  />
                )}
            </Form.Group>
            <br/>
            <Form.TextArea
              rows={7}
              placeholder='URLs (1 per row)'
              onChange={this.onBucketUrlsInput}
            />
            <br/>
            <Form.Input
              fluid={true} icon='key' iconPosition='left' placeholder='Bucket Cryptokey URL' value={this.state.bucketCryptokeyUrl} onChange={this.onBucketCryptokeyUrlInput}
            />
          </Form>
        </div>
        <Dimmer active={this.state.isLoading}>
          <Loader inverted={true} content='Creating Bucket' />
        </Dimmer>
      </Container>
    )
  }

  private onBucketNameInput (event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      bucketName: event.currentTarget.value
    })
  }

  private onBucketUrlsInput (event: SyntheticEvent<HTMLTextAreaElement>) {
    const textContent = event.currentTarget.value
    const bucketUrls = this.countBucketUrls(textContent)
    const bucketUrlsCount = bucketUrls.length

    this.setState({
      bucketUrls,
      bucketUrlsCount
    })
  }

  private onBucketUrlInput (event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      bucketUrl: event.currentTarget.value
    })
  }

  private onBucketCryptokeyUrlInput (event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      bucketCryptokeyUrl: event.currentTarget.value
    })
  }

  private handleCreateClick = (e: any, { name }: any) => {
    this.setState({
      isLoading: true
    })

    const bucketCreateData: IBucketCreate = {
      name: this.state.bucketName,
      files: this.state.bucketUrls,
      cryptokeyUrl: this.state.bucketCryptokeyUrl
    }

    setTimeout(() => {
      new BucketService().create(bucketCreateData)
        .then((response) => {
          if (response == null) {
            throw Error()
          }

          this.setState({
            bucketUrl: location.origin + '/bucket/' + response.uid  // eslint-disable-line
          })
        })
        .catch((err) => {
        })
        .finally(() => {
          this.setState({
            isLoading: false
          })
        })
    }, 800)
  }

  private countBucketUrls (textContent: string): IFile[] {
    const urls: IFile[] = []

    const regex = /(http:|https:)/ig
    const lines = textContent.split('\n')
    lines.forEach((line) => {
      if (line.match(regex)) {
        urls.push({
          url: line
        } as IFile)
      }
    })

    return (urls || [])
  }
}
