import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, } from 'semantic-ui-react'

import { IBucket } from '../../index.d';

interface IBucketTableRowProps {
  bucketData: IBucket
}

interface IBucketTableRowState {
}

export default class BucketTableRow extends Component<IBucketTableRowProps, IBucketTableRowState> {
  private bucketData: IBucket

  constructor (props: IBucketTableRowProps) {
    super(props)

    this.bucketData = props.bucketData
  }

  public render () {
    return (
      <Table.Row>
        <Table.Cell>
          { this.bucketData.uid }
        </Table.Cell>
        <Table.Cell>
          { this.bucketData.name }
        </Table.Cell>
        <Table.Cell>
          { this.bucketData.files.length }
        </Table.Cell>
        <Table.Cell>
          { this.bucketData.last_checked }
        </Table.Cell>
        <Table.Cell>
          <Link to={'/bucket/' + this.bucketData.uid + '/update'}>
            <Button style={{ backgroundColor: '#8A73FF', color: 'white' }}>Update</Button>
          </Link>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} disabled>Delete</Button>
        </Table.Cell>
      </Table.Row>
    )
  }
}
