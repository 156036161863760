import axios, { AxiosRequestConfig } from 'axios'

import { IBucket, IBucketArchive, IBucketCreate } from '../index.d'
import AuthService from './Auth'

import BucketCreateFailedError from '../errors/BucketCreateFailedError'
import BucketNotFoundError from '../errors/BucketNotFoundError'
import UnauthorizedError from '../errors/UnauthorizedError'

export default class BucketService {
  private readonly BASE_API: string =
    process.env.REACT_APP_SHAREURL_API_PROTOCOL === 'https' ? // remove port
      process.env.REACT_APP_SHAREURL_API_PROTOCOL + '://' +
      process.env.REACT_APP_SHAREURL_API_BASE + '/api' : // add port
      process.env.REACT_APP_SHAREURL_API_PROTOCOL + '://' +
      process.env.REACT_APP_SHAREURL_API_BASE + ':' +
      process.env.REACT_APP_SHAREURL_API_PORT + '/api'
  private readonly BASE_API_BUCKET: string = '/buckets'
  private readonly BASE_API_SEARCH: string = '/search'

  private readonly OK_SUCCESS_CODE: number = 200

  public async get (bucketId: string): Promise<IBucket> {
    try {
      const response = await axios.get(this.BASE_API + this.BASE_API_BUCKET + '/' + bucketId)

      if (response.status !== this.OK_SUCCESS_CODE) {
        throw new Error(response.data)
      }

      const bucketData: IBucket = response.data

      return bucketData
    } catch (err) {
      throw new BucketNotFoundError()
    }
  }

  public async getFull (bucketId: string): Promise<IBucket> {
    try {
      const token: string | null = new AuthService().getUserToken()
      if (token === null) {
        throw new UnauthorizedError('unauthorized')
      }

      const headers: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }

      const response = await axios.get(this.BASE_API + this.BASE_API_BUCKET + '/' + bucketId + '/full', headers)

      if (response.status !== this.OK_SUCCESS_CODE) {
        throw new Error(response.data)
      }

      const bucketData: IBucket = response.data

      return bucketData
    } catch (err) {
      throw new BucketNotFoundError()
    }
  }

  public async search (page: Number, query: String): Promise<IBucketArchive> {
    try {
      const token: string | null = new AuthService().getUserToken()
      if (token === null) {
        throw new UnauthorizedError('unauthorized')
      }

      const headers: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }

      const response = await axios.get(this.BASE_API + this.BASE_API_BUCKET + '/search/' + page + '/' + query, headers)

      if (response.status !== this.OK_SUCCESS_CODE) {
        throw new Error(response.data)
      }

      const archiveData: IBucketArchive = response.data

      return archiveData
    } catch (err) {
      // TODO: throw another error type
      throw new BucketNotFoundError()
    }
  }

  public async create (bucketCreateData: IBucketCreate): Promise<IBucket | null> {
    try {
      const token: string | null = new AuthService().getUserToken()
      if (token === null) {
        throw new UnauthorizedError('unauthorized')
      }

      const headers: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }

      const response = await axios.post(this.BASE_API + this.BASE_API_BUCKET, bucketCreateData, headers)

      if (response.status !== this.OK_SUCCESS_CODE) {
        throw new Error(response.data)
      }

      const bucketData: IBucket = response.data

      return bucketData
    } catch (err) {
      throw new BucketCreateFailedError(err.message)
    }
  }

  public async update (bucketUpdateData: IBucket): Promise<IBucket | null> {
    try {
      const token: string | null = new AuthService().getUserToken()
      if (token === null) {
        throw new UnauthorizedError('unauthorized')
      }

      const headers: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }

      const response = await axios.put(this.BASE_API + this.BASE_API_BUCKET, bucketUpdateData, headers)

      if (response.status !== this.OK_SUCCESS_CODE) {
        throw new Error(response.data)
      }

      const bucketData: IBucket = response.data

      return bucketData
    } catch (err) {
      // TODO: throw new error
      throw new BucketCreateFailedError(err.message)
    }
  }
}
