import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Button, Container, Menu, Segment } from 'semantic-ui-react'

import EventEmitter from '../EventEmitter'
import AuthService from '../services/Auth'

interface IHeaderProps {
  eventEmitter: EventEmitter
}

interface IHeaderState {
  activeItem: string,
  loggedIn: boolean,
  name: string
}

export default class Header extends Component<IHeaderProps, IHeaderState> {
  private eventEmitter: EventEmitter

  constructor (props: IHeaderProps) {
    super(props)
    this.state = {
      activeItem: 'bucket',
      loggedIn: false,
      name: ''
    }
    this.eventEmitter = props.eventEmitter
  }

  public handleItemClick = (e: any, { name }: any) => {
    this.setState({ activeItem: name })
  }

  public componentWillMount () {
    this.eventEmitter.on('name', (e: Event) => {
      this.setState({
        name: e.toString()
      })
    })
    this.eventEmitter.on('auth', (e: Event) => {
      if (e.toString() === 'loggedIn') {
        this.setState({
          loggedIn: true
        })
      }
    })

    const authService = new AuthService()
    if (authService.getUserData()) {
      this.setState({
        loggedIn: true
      })
    }
  }

  public render () {
    const { loggedIn } = this.state

    return (
      <Segment style={{ backgroundColor: '#171A21' }}>
        {loggedIn === true &&
          <Menu secondary={true} inverted={true}>
            <Container>
              <Menu.Item>
                ShareURL
                </Menu.Item>
              {this.state.name !== '' &&
                <Menu.Item
                  active={true}
                  onClick={this.handleItemClick}>
                  <Breadcrumb>
                    <Breadcrumb.Section>Bucket</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' style={{ color: 'white' }} />
                    <Breadcrumb.Section active={true}>{this.state.name}</Breadcrumb.Section>
                  </Breadcrumb>
                </Menu.Item>
              }
              <Menu.Menu position='right'>
              <Menu.Item>
                  <Link to='/archive'>
                    <Button style={{ backgroundColor: '#8A73FF', color: 'white' }}>Archive</Button>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to='/create'>
                    <Button style={{ backgroundColor: '#8A73FF', color: 'white' }}>Create Bucket</Button>
                  </Link>
                </Menu.Item>
              </Menu.Menu>
            </Container>
          </Menu>
        }
      </Segment>
    )
  }
}
