export default class StorageService {

  public get (key: string): string | null {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.getItem(key)
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.getItem(key)
    }

    return null
  }

  public set (key: string, value: string, isLocalStorage: boolean = true): boolean {
    if (value === undefined || value === '') {
      return false
    }

    if (localStorage && isLocalStorage) {
      localStorage.setItem(key, value)
      return true
    }

    if (sessionStorage && !isLocalStorage) {
      sessionStorage.setItem(key, value)
      return true
    }

    return false
  }

  public clear (key: string): boolean {
    if (localStorage && localStorage.getItem(key)) {
      localStorage.removeItem(key)
      return true
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      sessionStorage.removeItem(key)
      return true
    }

    return false
  }

  public clearAll (): boolean {
    if (localStorage) {
      localStorage.clear()
      return true
    }

    if (sessionStorage) {
      sessionStorage.clear()
      return true
    }

    return false
  }

}
