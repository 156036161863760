import EventEmitter from 'eventemitter3'

export default class BaseClient {
  private eventEmitter: EventEmitter

  constructor () {
    this.eventEmitter = new EventEmitter()
  }

  public on (eventName: string, listener: EventListener) {
    this.eventEmitter.on(eventName, listener)
  }

  public removeEventListener (eventName: string, listener: EventListener) {
    this.eventEmitter.removeListener(eventName, listener)
  }

  public emit (event: string, payload: string, error = false) {
    this.eventEmitter.emit(event, payload, error)
  }

  public getEventEmitter () {
    return this.eventEmitter
  }
}
