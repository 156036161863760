import React, { Component } from 'react'
import { Button, Icon, List } from 'semantic-ui-react'

import EventEmitter from '../../EventEmitter'

interface IBucketAdsProps {
  eventEmitter: EventEmitter
}

interface IBucketAdsState {
}

export default class BucketAds extends Component<IBucketAdsProps, IBucketAdsState> {
  private eventEmitter: EventEmitter

  constructor (props: IBucketAdsProps) {
    super(props)

    this.eventEmitter = props.eventEmitter
  }

  /**
    *   <List.Item key='buyVpn'>
          <List.Content floated='right'>
            <Button animated={true} style={{ backgroundColor: '#42ADE4' }} onClick={this.gotoBuyVpn}>
              <Button.Content hidden={true} style={{ color: 'white' }}>
                <Icon name='arrow right' />
              </Button.Content>
              <Button.Content visible={true} style={{ color: 'white' }}>
                <Icon name='shop' />
              </Button.Content>
            </Button>
          </List.Content>
          <List.Icon style={{ color: '#42ADE4' }} size='big' name='shield' />
          <List.Content>
            <List.Header style={{ color: '#42ADE4' }}>Always use a VPN</List.Header>
            <List.Description style={{ color: '#b0b0b0' }}>& download files anonymously</List.Description>
          </List.Content>
        </List.Item>
   */

  public render () {
    return (
      <React.Fragment>
        <List.Item key='buyGame'>
          <List.Content floated='right'>
            <Button animated={true} style={{ backgroundColor: '#FF5400' }} onClick={this.gotoBuyGame}>
              <Button.Content hidden={true} style={{ color: 'white' }}>
                <Icon name='arrow right' />
              </Button.Content>
              <Button.Content visible={true} style={{ color: 'white' }}>
                <Icon name='shop' />
              </Button.Content>
            </Button>
          </List.Content>
          <List.Icon style={{ color: '#FF5400' }} size='big' name='gamepad' />
          <List.Content>
            <List.Header style={{ color: '#FF5400' }}>BUY THE GAME</List.Header>
            <List.Description style={{ color: '#b0b0b0' }}>& support the developers</List.Description>
          </List.Content>
        </List.Item>
      </React.Fragment>
    )
  }

  private gotoBuyVpn () {
    window.open('https://windscribe.com/?affid=onazqytb', '_blank')
  }

  private gotoBuyGame () {
    window.open('https://www.instant-gaming.com/igr/cgames/', '_blank')
  }
}
