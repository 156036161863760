import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ReactGA from 'react-ga'

import 'semantic-ui-css/semantic.min.css'
import './App.css'

import EventEmitter from './EventEmitter'

import Archive from './components/Archive'
import Bucket from './components/Bucket'
import Create from './components/Create'
import Header from './components/Header'
import Login from './components/Login/Login'
import NotFound from './components/NotFound'
import Update from './components/Update';

ReactGA.initialize('UA-64479604-26', { testMode: true })
ReactGA.pageview(window.location.pathname + window.location.search)

interface IAppProps {
}

interface IAppState {
}

export default class App extends Component<IAppProps, IAppState> {
  private eventEmitter: EventEmitter

  constructor (props: IAppProps) {
    super(props)
    this.state = {}

    this.eventEmitter = new EventEmitter()
  }

  public render () {
    return (
      <BrowserRouter>
        <div className='App'>
          <Header eventEmitter={this.eventEmitter}/>
          <Switch>
            <Route exact={true} path='/' />
            <Route
              path='/create'
              render={
                (props) =>  <Create {...props} eventEmitter={this.eventEmitter}/>
              }
            />
            <Route
              path='/archive'
              render={
                (props) =>  <Archive {...props} eventEmitter={this.eventEmitter}/>
              }
            />
            <Route
              path='/bucket/:bucketId/update'
              render={
                (props) =>  <Update {...props} eventEmitter={this.eventEmitter}/>
              }
            />
            <Route
              path='/bucket/:bucketId'
              render={
                (props) =>  <Bucket {...props} eventEmitter={this.eventEmitter}/>
              }
            />
            <Route
              path='/login'
              render={
                (props) =>  <Login {...props} eventEmitter={this.eventEmitter}/>
              }
            />
            <Route
              render={
                (props) =>  <NotFound {...props}/>
              }
            />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}
