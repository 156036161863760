import React, { Component, SyntheticEvent } from 'react'
import { Dropdown } from 'semantic-ui-react'

import EventEmitter from '../../EventEmitter'

import { hoster, IBucket } from '../../index.d'
import { BucketConsumer } from './BucketContext'

interface IBucketDropdownProps {
  eventEmitter: EventEmitter
}

interface IBucketDropdownState {

}

export default class BucketDropdown extends Component<IBucketDropdownProps, IBucketDropdownState> {
  private eventEmitter: EventEmitter

  constructor (props: IBucketDropdownProps) {
    super(props)
    this.state = {
      hoster: ''
    }

    this.eventEmitter = props.eventEmitter

    this.onDropdownChange = this.onDropdownChange.bind(this)
  }

  public render () {
    return (
      <BucketConsumer>
        {(bucketContext) => bucketContext && (
          <Dropdown style={{ backgroundColor: '#171A21', color: 'white' }}
            placeholder='Select Hoster'
            selection={true}
            options={this.getDropdownItems(bucketContext)}
            onChange={this.onDropdownChange}
          />
        )}
      </BucketConsumer>
    )
  }

  private getDropdownItems (bucketData: IBucket) {
    const dropdownOptions: any = []

    if (bucketData.parsed !== undefined && bucketData.parsed !== {}) {
      const parsed = bucketData.parsed
      const keys = Object.keys(parsed).sort((x, y) => {
        return hoster[x].priority - hoster[y].priority
      })

      const dropdownOptions: any = []

      keys.forEach((key) => {
        dropdownOptions.push({
          key,
          text: hoster[key].displayName,
          value: key,
          image: {
            avatar: true,
            src: hoster[key].icon
          }
        })
      })
      return dropdownOptions
    }
    return dropdownOptions
  }

  private onDropdownChange (e: any, { value }: any) {
    if (value == null) {
      return
    }

    const hosters = Object.keys(hoster).sort((x, y) => {
      return hoster[x].priority - hoster[y].priority
    }).indexOf(value) >= 0 ? value : Object.keys(hoster).sort((x, y) => {
      return hoster[x].priority - hoster[y].priority
    })[0]

    this.setState({
      hosters
    })

    this.eventEmitter.emit('onHosterChange', hosters)
  }
}
