export default class InvalidLoginError extends Error {
  public code: string | undefined

  constructor (message?: string, code?: string) {
    super(message)

    this.code = code

    Object.setPrototypeOf(this, InvalidLoginError.prototype)
  }
}
